import React from 'react';
import { Typography, Box, Paper, List, ListItem, ListItemText, ListItemIcon, Avatar, Divider } from '@mui/material';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const RoomContainer = styled(Paper)`
  padding: 3rem;
  max-width: 1200px;
  margin: 3rem auto;
  background-color: #2d2d2d;
  color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
`;

const TeamsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
`;

const TeamBox = styled(Box)`
  width: 48%;
  background-color: #3d3d3d;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const TeamName = styled(Typography)`
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${props => props.color};
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
`;

const PlayerList = styled(List)`
  background-color: #2d2d2d;
  border-radius: 8px;
  overflow: hidden;
`;

const PlayerItem = styled(ListItem)`
  padding: 0.75rem 1rem;
  &:nth-child(odd) {
    background-color: #353535;
  }
`;

const PlayerName = styled(ListItemText)`
  color: #ffffff;
`;

const AntiCheatStatus = styled(Box)`
  display: flex;
  align-items: center;
`;

const StatusIcon = styled(ListItemIcon)`
  min-width: 30px;
`;

const StatusText = styled(Typography)`
  font-size: 0.9rem;
  margin-left: 0.5rem;
  color: #bdbdbd;
`;

const TeamHeader = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const TeamLogo = styled(Avatar)`
  width: 60px;
  height: 60px;
  margin-right: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
`;

const MatchInfo = styled(Box)`
  margin-bottom: 3rem;
  text-align: center;
  padding: 1.5rem;
  background-color: #3d3d3d;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const SkedACRoom = () => {
  const matchInfo = {
    date: "15 avril 2024",
    time: "20:00",
    tournament: "Championnat Français de CS:GO"
  };

  const teams = [
    {
      name: "Équipe Alpha",
      color: "#64b5f6",
      logo: "/path/to/alpha_logo.png",
      players: [
        { name: "Joueur 1", antiCheatConnected: true },
        { name: "Joueur 2", antiCheatConnected: true },
        { name: "Joueur 3", antiCheatConnected: false },
        { name: "Joueur 4", antiCheatConnected: true },
        { name: "Joueur 5", antiCheatConnected: true },
      ]
    },
    {
      name: "Équipe Bravo",
      color: "#ff7043",
      logo: "/path/to/bravo_logo.png",
      players: [
        { name: "Joueur 6", antiCheatConnected: true },
        { name: "Joueur 7", antiCheatConnected: false },
        { name: "Joueur 8", antiCheatConnected: true },
        { name: "Joueur 9", antiCheatConnected: true },
        { name: "Joueur 10", antiCheatConnected: true },
      ]
    }
  ];

  return (
    <RoomContainer elevation={3}>
      <Typography variant="h3" align="center" gutterBottom sx={{ color: '#ffffff', marginBottom: '2rem', fontWeight: 'bold' }}>
        Salle de match
      </Typography>

      <MatchInfo>
        <Typography variant="h5" sx={{ color: '#ffffff', marginBottom: '0.5rem' }}>
          {matchInfo.tournament}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: '#bdbdbd' }}>
          {matchInfo.date} à {matchInfo.time}
        </Typography>
      </MatchInfo>

      <TeamsContainer>
        {teams.map((team, index) => (
          <TeamBox key={index}>
            <TeamHeader>
              <TeamLogo src={team.logo} alt={`Logo ${team.name}`} />
              <TeamName color={team.color}>{team.name}</TeamName>
            </TeamHeader>
            <PlayerList>
              {team.players.map((player, playerIndex) => (
                <React.Fragment key={playerIndex}>
                  <PlayerItem>
                    <PlayerName primary={player.name} />
                    <AntiCheatStatus>
                      <StatusIcon>
                        {player.antiCheatConnected ? (
                          <CheckCircleIcon sx={{ color: '#4caf50' }} />
                        ) : (
                          <ErrorIcon sx={{ color: '#f44336' }} />
                        )}
                      </StatusIcon>
                      <StatusText>
                        {player.antiCheatConnected ? "Connecté" : "Déconnecté"}
                      </StatusText>
                    </AntiCheatStatus>
                  </PlayerItem>
                  {playerIndex < team.players.length - 1 && <Divider sx={{ backgroundColor: '#4d4d4d' }} />}
                </React.Fragment>
              ))}
            </PlayerList>
          </TeamBox>
        ))}
      </TeamsContainer>
    </RoomContainer>
  );
};

export default SkedACRoom;
