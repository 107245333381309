import React, { useState, useMemo } from 'react';
import { Typography, Box, Link, Paper, Stepper, Step, StepLabel, Button, StepContent, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import styled from 'styled-components';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';
import SecurityIcon from '@mui/icons-material/Security';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import GroupIcon from '@mui/icons-material/Group';
import TimerIcon from '@mui/icons-material/Timer';
import GavelIcon from '@mui/icons-material/Gavel';

const StyledPaper = styled(Paper)`
  padding: 2rem;
  max-width: 800px;
  margin: 2rem auto;
  background-color: #1e1e1e;
  color: #ffffff;
`;

const StyledBox = styled(Box)`
  margin: 1.5rem 0;
`;

const PrerequisBox = styled(Box)`
  background-color: #2d2d2d;
  padding: 1.5rem;
  border-radius: 8px;
  margin: 1rem 0 2rem 0;
  border: 1px solid #3d3d3d;
`;

const StyledLink = styled(Link)`
  color: #64b5f6;
  &:hover {
    color: #90caf9;
  }
`;

const WarningText = styled(Typography)`
  color: #b3b3b3;
  font-style: italic;
  margin-top: 2rem;
  font-size: 0.9rem;
`;

const ResultImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.5);
`;

const StyledStepper = styled(Stepper)`
  .MuiStepLabel-label {
    color: #ffffff;
  }
  .MuiStepIcon-root {
    color: #64b5f6;
  }
  .MuiStepIcon-text {
    fill: #1e1e1e;
  }
  .MuiStepConnector-line {
    border-color: #3d3d3d;
  }
`;

const StepButtons = styled(Box)`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
`;

const StepContentWrapper = styled(Box)`
  padding: 1rem 0;
  color: #ffffff;
`;

const LanguageSelect = styled(Select)`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: #333;
  color: white;
  width: 80px;
  height: 30px;
  font-size: 0.8rem;
  & .MuiSelect-icon {
    color: white;
  }
`;

const FlagMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  background-color: #333;
  color: white;
  &:hover {
    background-color: #444;
  }
`;

const FlagImage = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 5px;
`;

const SkedACSetup = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { language, changeLanguage } = useLanguage();
  const { t } = useTranslation();
  const [openRGPD, setOpenRGPD] = useState(true);

  const flags = {
    fr: '/fr.png',
    en: '/gb.png',
    de: '/de.png',
    it: '/it.png',
    es: '/es.png'
  };

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleAcceptRGPD = () => {
    setOpenRGPD(false);
  };

  const steps = useMemo(() => {
    return t('steps', { returnObjects: true }).map((step, index) => ({
      label: step.label,
      content: (
        <StepContentWrapper key={`step-content-${index}`}>
          <Typography variant="body1" paragraph>
            {index === 0 ? (<>
              <p>{step.content}</p>
              <a href="https://arbitrage.sked.gg/sked_ac.exe" target="_blank">https://arbitrage.sked.gg/sked_ac.exe</a>
            </>) : step.content}
          </Typography>
        </StepContentWrapper>
      )
    }));
  }, [t]);

  return (
    <>
      <Dialog 
        open={openRGPD} 
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown
        disableBackdropClick
        PaperProps={{
          style: {
            backgroundColor: '#1e1e1e',
            color: '#ffffff',
            minHeight: '60vh',
          },
        }}
      >
        <DialogTitle sx={{ fontSize: '1.5rem', borderBottom: '1px solid #3d3d3d', padding: '1.5rem' }}>
          Information sur le traitement des données personnelles
        </DialogTitle>
        <DialogContent sx={{ padding: '2rem' }}>
          <Typography paragraph>
            Dans le cadre de l'utilisation de notre programme de surveillance, nous collectons certaines données afin de <strong>détecter et de prévenir les comportements frauduleux</strong>, en particulier dans les cas de tricherie ou de violation des règles établies. Nous nous engageons à respecter le <u>Règlement Général sur la Protection des Données (RGPD)</u> et à protéger vos droits.
          </Typography>
          
          <Typography variant="h6" sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <DataUsageIcon /> 1. Finalité des données
          </Typography>
          <Typography paragraph>
            Les données sont collectées dans le but <strong>exclusif</strong> de :
            <ul>
              <li><strong>Identifier les comportements anormaux</strong> pouvant indiquer des activités frauduleuses</li>
              <li><strong>Maintenir l'intégrité et la sécurité</strong> des systèmes et des utilisateurs légitimes</li>
            </ul>
            Ces données ne seront <u>pas utilisées à d'autres fins</u>.
          </Typography>

          <Typography variant="h6" sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <SecurityIcon /> 2. Données collectées
          </Typography>
          <Typography paragraph>
            Les informations collectées par le programme incluent :
            <ul>
              <li>Captures d'écran (pour détecter des activités suspectes).</li>
              <li>Liste des logiciels installés (pour identifier des outils non autorisés).</li>
              <li>État des points de sécurité Windows par défaut (pour vérifier la sécurité).</li>
              <li>Liste des processus actifs (pour détecter des applications suspectes).</li>
              <li>Adresse IP (pour des raisons de sécurité et d'identification).</li>
            </ul>
          </Typography>

          <Typography variant="h6" sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <GroupIcon /> 3. Destinataires des données
          </Typography>
          <Typography paragraph>
            Les données collectées sont accessibles uniquement à l'équipe Sked AC, composée des membres suivants :
            <ul>
              <li>Detective</li>
              <li>Reborn</li>
              <li>Benjamin</li>
            </ul>
            Aucun tiers ou personne extérieure à cette équipe n'a accès à ces données.
          </Typography>

          <Typography variant="h6" sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <TimerIcon /> 4. Durée de conservation des données
          </Typography>
          <Typography paragraph>
            Les données collectées sont conservées pour une durée maximale de 1 semaine. Elles sont supprimées automatiquement après cette période, sauf en cas d'incident ou de suspicion de fraude, où elles peuvent être conservées plus longtemps pour les besoins d'une enquête.
          </Typography>

          <Typography variant="h6" sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <GavelIcon /> 5. Droits des utilisateurs
          </Typography>
          <Typography paragraph>
            En raison de la finalité légitime liée à la prévention de la fraude, les utilisateurs disposent de droits limités :
            <ul>
              <li>Droit de rectification : Si des erreurs sont identifiées dans vos données, vous pouvez demander leur correction.</li>
              <li>Limitation des droits de suppression : Les demandes de suppression ne peuvent être acceptées immédiatement si les données sont nécessaires pour une enquête en cours ou pour garantir l'intégrité des systèmes. Une fois l'enquête terminée, ces données seront supprimées conformément à notre politique de conservation.</li>
            </ul>
          </Typography>

          <Typography variant="h6" sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <LockIcon /> 6. Sécurisation des données
          </Typography>
          <Typography paragraph>
            Toutes les données collectées sont sécurisées et accessibles uniquement par les membres autorisés de l'équipe Sked AC. Nous utilisons des technologies avancées pour protéger vos informations contre tout accès non autorisé ou divulgation.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: '1.5rem', borderTop: '1px solid #3d3d3d' }}>
          <Button 
            onClick={handleAcceptRGPD}
            variant="contained"
            sx={{ 
              bgcolor: '#64b5f6', 
              color: '#ffffff',
              padding: '0.5rem 2rem',
              '&:hover': { bgcolor: '#90caf9' } 
            }}
          >
            J'accepte les conditions
          </Button>
        </DialogActions>
      </Dialog>
      <StyledPaper elevation={3}>
        <LanguageSelect
          value={language}
          onChange={handleLanguageChange}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: '#333',
              },
            },
          }}
        >
          {Object.entries(flags).map(([code, flagSrc]) => (
            <FlagMenuItem key={code} value={code}>
              <FlagImage src={flagSrc} alt={`Drapeau ${code}`} />
            </FlagMenuItem>
          ))}
        </LanguageSelect>

        <Typography variant="h4" align="center" gutterBottom sx={{ color: '#ffffff' }}>
          {t('title')}
        </Typography>

        <StyledStepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={`step-${index}`}>
              <StepLabel>
                <Typography variant="h6" sx={{ color: '#ffffff' }}>
                  {step.label}
                </Typography>
              </StepLabel>
              <StepContent>
                {step.content}
                <StepButtons>
                  <Button
                    variant="contained"
                    onClick={handleBack}
                    disabled={index === 0}
                    sx={{ bgcolor: '#2d2d2d', '&:hover': { bgcolor: '#3d3d3d' } }}
                  >
                    {t('buttons.previous')}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={index === steps.length - 1 ? handleReset : handleNext}
                    sx={{ bgcolor: '#64b5f6', '&:hover': { bgcolor: '#90caf9' } }}
                  >
                    {index === steps.length - 1 ? t('buttons.finish') : t('buttons.next')}
                  </Button>
                </StepButtons>
              </StepContent>
            </Step>
          ))}
        </StyledStepper>

        {activeStep === steps.length && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5" gutterBottom sx={{ color: '#64b5f6' }}>
              <strong>{t('completion.title')}</strong>
            </Typography>
            <Typography variant="body1" sx={{ color: '#ffffff' }}>
              {t('completion.message')}
            </Typography>
            <Button 
              onClick={handleReset} 
              sx={{ 
                mt: 2, 
                bgcolor: '#2d2d2d', 
                color: '#ffffff',
                '&:hover': { bgcolor: '#3d3d3d' } 
              }}
            >
              {t('buttons.restart')}
            </Button>
          </Box>
        )}

        <WarningText align="center">
          {t('footer.warning')}
        </WarningText>

        <Typography 
          variant="body1" 
          align="center" 
          sx={{ color: '#ffffff', mt: 2, mb: 2 }}
        >
          {t('footer.support')}
        </Typography>

        <Typography 
          variant="body1" 
          align="center" 
          sx={{ color: '#64b5f6', mt: 3, mb: 1 }}
        >
          <strong>{t('footer.result')}</strong>
        </Typography>

        <Box textAlign="center">
          <ResultImage 
            src="/skedac.png" 
            alt="Résultat attendu après installation de Sked AC" 
          />
        </Box>
      </StyledPaper>
    </>
  );
};

export default SkedACSetup;
