import React, { useState, useEffect } from 'react';
import { LanguageProvider } from './contexts/LanguageContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CircularProgress, Box, CssBaseline, ThemeProvider, createTheme, GlobalStyles } from '@mui/material';
import Start from './pages/Start';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import SkedAC from './pages/SkedAC';
import SkedACSetup from './pages/SkedACSetup';
import { useTranslation } from 'react-i18next';
import SkedACRoom from './pages/SkedACRoom';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const globalStyles = (
  <GlobalStyles
    styles={{
      '*::-webkit-scrollbar': {
        width: '10px',
      },
      '*::-webkit-scrollbar-track': {
        background: '#2b2b2b',
      },
      '*::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '5px',
      },
      '*::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    }}
  />
);

function App() {
    const { i18n, t } = useTranslation();
    const [, setCurrentLanguage] = useState(i18n.language);

    useEffect(() => {
        const changeLanguage = (lng) => {
            console.log('Language changed to:', lng);
            setCurrentLanguage(lng);
        };

        i18n.on('languageChanged', changeLanguage);

        return () => {
            i18n.off('languageChanged', changeLanguage);
        };
    }, [i18n]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <LanguageProvider>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                {globalStyles}
                {loading ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <p>
                            {t('loaddata')}
                        </p>
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'inline-flex',
                            }}
                        >
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: 'grey.700'
                                }}
                            />
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: 'grey.500',
                                    position: 'absolute',
                                    left: 0,
                                    '& .MuiCircularProgress-circle': {
                                        strokeLinecap: 'round',
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Router>
                        {!window.location.pathname.includes('/sked-ac') && <Header />}
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/:matchId/:userToken" element={<Start />} />
                            <Route path="/sked-ac/:userToken" element={<SkedAC />} />
                            <Route path="/setup/sked-ac" element={<SkedACSetup />} />
                            <Route path="/sked-ac/room/:roomId" element={<SkedACRoom />} />
                        </Routes>
                        {!window.location.pathname.includes('/sked-ac') && <Footer />}
                    </Router>
                )}
            </ThemeProvider>
        </LanguageProvider>
    );
}

export default App;