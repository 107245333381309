import React, { useEffect, useState } from 'react';
import { loginWithToken } from '../services/api';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { 
  Button, 
  Container, 
  Paper, 
  List, 
  ListItem, 
  ListItemText,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Chip,
  IconButton,
  Alert,
  TextField,
  InputAdornment
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr';
import CircularProgress from '@mui/material/CircularProgress';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import LinearProgress from '@mui/material/LinearProgress';
import DownloadIcon from '@mui/icons-material/Download';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';

const StyledContainer = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 1rem auto;
  max-width: 95% !important;
`;

const StyledPaper = styled(Paper)`
  padding: 2rem;
  margin: 1rem 0;
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
`;

const StyledListItem = styled(ListItem)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
`;

const UserInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1rem;
`;

const StyledChip = styled(Chip)`
  margin: 0 4px;
  background-color: ${props => props.variant === "outlined" ? "rgba(255, 255, 255, 0.1)" : "inherit"};
  color: ${props => props.variant === "outlined" ? "#fff" : "inherit"};
  border-color: rgba(255, 255, 255, 0.3);

  &.MuiChip-outlined {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const UserDetailsBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  margin-top: 8px;
  position: relative;
`;

const BanButton = styled(Button)`
  position: absolute;
  right: 24px;
  min-width: 100px;
`;

const HighlightedChip = styled(StyledChip)`
  background-color: rgba(144, 202, 249, 0.15) !important;
  border-color: #90caf9 !important;
  font-weight: 500;
  
  .MuiChip-label {
    color: #90caf9;
  }
`;

const FullScreenDialog = styled(Dialog)`
  .MuiDialog-paper {
    margin: 0;
    max-width: none;
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
  }
`;

const ImageModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    max-width: 90vw;
    max-height: 90vh;
    object-fit: contain;
  }
`;

const DataSection = styled(Paper)`
  margin: 16px 0;
  padding: 16px;
  background-color: #1e1e1e;
  color: #fff;
  max-height: 600px;
  overflow-y: auto;
  font-family: 'Consolas', monospace;
  font-size: 0.85rem;
  
  pre {
    margin: 0;
    white-space: pre-wrap;
  }

  .data-item {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .data-row {
    display: grid;
    grid-template-columns: 130px 1fr;
    gap: 6px;
    padding: 3px 0;
  }

  .data-label {
    color: #90caf9;
    font-weight: 500;
    font-size: 0.8rem;
  }

  .data-value {
    color: rgba(255, 255, 255, 0.85);
    font-size: 0.8rem;
    line-height: 1.4;
  }
`;

const StyledImageList = styled(ImageList)`
  width: 100%;
  padding: 16px;
  background-color: #1e1e1e;
  border-radius: 8px;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
`;

const StyledImageListItem = styled(ImageListItem)`
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  width: 140px !important;
  height: 180px !important;
  
  &:hover {
    transform: scale(1.05);
  }
  
  .time-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 4px 8px;
    font-size: 0.8rem;
    font-family: 'Consolas', monospace;
  }

  img {
    width: 140px !important;
    height: 180px !important;
    object-fit: cover !important;
  }
`;

const HardwareGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-top: 16px;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const HardwareItem = styled(Paper)`
  background-color: rgba(255, 255, 255, 0.08);
  padding: 12px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.12);
  }
  
  h4 {
    margin: 0 0 8px 0;
    color: #90caf9;
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  p {
    margin: 4px 0;
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.85);
    word-break: break-word;
    line-height: 1.4;
  }
`;

const HardwareSection = styled(Paper)`
  margin: 16px 0;
  padding: 16px;
  background-color: #1e1e1e;
  color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
`;

const StatusSection = styled(Paper)`
  margin: 8px 0;
  padding: 8px;
  background-color: #1e1e1e;
  border-radius: 8px;
  display: flex;
  gap: 8px;
`;

const StatusCard = styled(Paper)`
  flex: 1;
  padding: 12px;
  background-color: ${props => props.active ? 'rgba(102, 187, 106, 0.1)' : 'rgba(255, 107, 107, 0.1)'};
  border: 1px solid ${props => props.active ? 'rgba(102, 187, 106, 0.3)' : 'rgba(255, 107, 107, 0.3)'};
  border-radius: 8px;
  
  .status-title {
    color: #fff;
    font-size: 0.9rem;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .status-value {
    font-size: 1rem;
    font-weight: 500;
    color: ${props => props.active ? '#66bb6a' : '#ff6b6b'};
  }

  button {
    padding: 2px 8px;
    font-size: 0.75rem;
    height: 20px;
    min-width: 40px;
  }
`;

const InfoHighlight = styled(Typography)`
  color: #90caf9;
  font-weight: 500;
  background-color: rgba(144, 202, 249, 0.15);
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #90caf9;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IPSection = styled(Box)`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 12px;
`;

const IPCard = styled(Paper)`
  background: linear-gradient(135deg, rgba(25, 118, 210, 0.05) 0%, rgba(25, 118, 210, 0.1) 100%);
  padding: 16px 24px;
  border: 1px solid rgba(25, 118, 210, 0.2);
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.2s ease-in-out;
  position: relative;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(25, 118, 210, 0.15);
    border-color: rgba(25, 118, 210, 0.3);
  }
  
  .ip-label {
    color: #90caf9;
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    min-width: 100px;
  }
  
  .ip-value {
    color: #fff;
    font-family: 'Consolas', monospace;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .ip-buttons {
    margin-left: auto;
    display: flex;
    gap: 8px;
  }
`;

const WarningBanner = styled(Box)`
  background: linear-gradient(45deg, #ff4444 30%, #ff1111 90%);
  color: white;
  padding: 8px 16px;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const SuspiciousHighlight = styled.span`
  background-color: rgba(255, 68, 68, 0.2);
  padding: 2px 4px;
  border-radius: 2px;
  font-weight: bold;
  color: #ff4444;
`;

const SUSPICIOUS_KEYWORDS = {
    process: [
        'cronus', 'zen', 'titan', 'rewasd', 'xim', 'ds4windows', 'inputmapper', 
        'vjoy', 'emulator', 'macro', 'script', 'autohotkey', 'turboing', 
        'virtual', 'remap',
        'titan one', 'titan two', 'strike pack', 'mayflash', 'brook', 'keyboardmouse',
        'xim apex', 'beloader', 'dominator', 'maxaim', 'gpc', 'gtuner', 'cronuszen',
        'cronusmax', 'cronus pro', 'cronus zen', 'cronusmax plus'
    ],
    network: [
        'cronus.com', 'cronusmax.com', 'consoletuner.com', 'xim-tech.com',
        'ds4windows.com', 'inputmapper.com', 'titan.com',
        'titanone.com', 'strikepack.com', 'brookaccessory.com', 'mayflash.com',
        'maxaim.com', 'gtuner.com', 'cronusmax.com', 'cronuszen.com'
    ],
    controllers: [
        'Virtual', 'Emulated', 'Emulator', 'vJoy', 'vController', 'vGamepad',
        'Virtual Gamepad', 'Virtual Controller', 'Virtual Device', 'Virtual HID',
        'Emulated Controller', 'Emulated Gamepad', 'Emulated Device',
        
        'Cronus', 'Zen', 'XIM', 'Titan', 'Brook', 'MaxShoot',
        'CronusMax', 'TitanTwo', 'XIM Apex', 'XIM4',
        
        'reWASD', 'DS4Windows', 'InputMapper', 'JoyToKey',
        'x360ce', 'Xpadder', 'AntiMicro', 'Joy2Key',
        
        'Input Redirector', 'Input Converter',
        'Controller Emulator', 'Gamepad Emulator',
        
        'Macro', 'Script', 'Remap', 'Turbo', 'Rapid Fire',
        'Modified', 'Unlicensed',
        'Titan One', 'Titan Two', 'Strike Pack', 'Strike Pack FPS Dominator',
        'Mayflash Magic-S Pro', 'Brook Super Converter', 'XIM Apex',
        'BeLoader', 'Dominator Controller', 'MaxAim DI', 'GPC Scripts',
        'GTuner', 'Cronus Zen', 'CronusMAX PLUS', 'Cronus Pro'
    ]
};

const LEGITIMATE_DEVICES = [
    'usb hub',
    'concentrateur usb',
    'hub usb standard',
    'generic usb hub',
    'root hub',
    'composite device',
    'périphérique composite',
    'standard hub',
    'lightspeed',
    'logitech',
    'receiver',
    'wireless receiver',
    'contrôleur hôte usb standard',
    'standard usb host controller',
    'root device',
    'routeur racine',
    'usb root',
    'usb4 root',
    'root_device_router',
    'périphérique usb composite',
    'usb composite device',
    'appareil générique usb',
    'dispositif routeur',
    'contrôleur d\'hôte compatible xhci',
    'contrôleur d\'hôte xhci',
    'intel(r) usb',
    'microsoft',
    'extensible'
];

const isItemSuspicious = (item, type) => {
    if (!item || !SUSPICIOUS_KEYWORDS[type]) return false;
    const itemStr = JSON.stringify(item).toLowerCase();
    return SUSPICIOUS_KEYWORDS[type].some(keyword => 
        itemStr.includes(keyword.toLowerCase())
    );
};

const isControllerSuspicious = (device) => {
    if (!device) return false;
    const deviceStr = JSON.stringify(device).toLowerCase();
    
    if (LEGITIMATE_DEVICES.some(device => deviceStr.includes(device.toLowerCase()))) {
        return false;
    }

    return SUSPICIOUS_KEYWORDS.controllers.some(keyword => 
        deviceStr.toLowerCase().includes(keyword.toLowerCase())
    );
};

const UserGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  margin-top: 16px;
  
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`;

const UserCard = styled(Paper)`
  padding: 16px;
  background-color: ${props => props.highlight ? 'rgba(255, 68, 68, 0.1)' : '#1e1e1e'};
  border: 1px solid ${props => props.highlight ? 'rgba(255, 68, 68, 0.3)' : 'rgba(255, 255, 255, 0.1)'};
  
  @media (max-width: 600px) {
    padding: 12px;
  }
`;

const StatusChip = styled(Chip)`
  margin: 0 4px;
  font-weight: 500;
  font-size: 0.85rem;
  height: 28px;
  width: 100%;
  justify-content: space-between;
  
  @media (max-width: 600px) {
    margin: 2px 0;
  }
  
  &.ip-info {
    background-color: rgba(25, 118, 210, 0.15);
    color: #90caf9;
    border: 1px solid rgba(25, 118, 210, 0.3);
  }

  &.banned {
    background-color: rgba(255, 152, 0, 0.15);
    color: #ffb74d;
    border: 1px solid rgba(255, 152, 0, 0.3);
  }

  &.force-playing {
    background-color: rgba(244, 67, 54, 0.15);
    color: #ef9a9a;
    border: 1px solid rgba(244, 67, 54, 0.3);
  }

  &.normal-status {
    background-color: rgba(76, 175, 80, 0.15);
    color: #81c784;
    border: 1px solid rgba(76, 175, 80, 0.3);
  }

  &.charlie-delta-active {
    background-color: rgba(244, 67, 54, 0.15);
    color: #ef9a9a;
    border: 1px solid rgba(244, 67, 54, 0.3);
  }

  &.isolation-active {
    background-color: rgba(76, 175, 80, 0.15);
    color: #81c784;
    border: 1px solid rgba(76, 175, 80, 0.3);
  }
`;

const StyledBox = styled(Box)`
  &:not(:last-child) {
    margin-bottom: 2rem;  // Ajoute une marge en bas de chaque Box sauf la dernière
    padding-bottom: 2rem; // Ajoute un padding en bas
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); // Ajoute une ligne de séparation
  }
`;

// Ajout du style pour la liste de fichiers
const FileListContainer = styled(Paper)`
  margin: 16px 0;
  background-color: #1e1e1e;
  color: #fff;
`;

const FileListItem = styled(ListItem)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .file-info {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .file-time {
    color: #90caf9;
    font-family: 'Consolas', monospace;
    min-width: 100px;
  }
  
  .file-name {
    color: rgba(255, 255, 255, 0.85);
  }
`;

// Ajoutez ces nouveaux styled components
const SearchContainer = styled(Box)`
  margin: 16px 0;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const SearchInput = styled('input')`
  flex: 1;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #90caf9;
  }
  
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const SuspiciousSection = styled(Box)`
  margin: 8px 0 16px;
  padding: 12px;
  background-color: rgba(255, 68, 68, 0.05);
  border: 2px solid #ff4444;
  border-radius: 8px;
`;

const SuspiciousHeader = styled(Typography)`
  color: #ff4444;
  font-weight: bold;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  
  &::before {
    content: '⚠️';
  }
`;

const PlayerName = styled(Typography)`
  background-color: #2c2c2c;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #444;
  color: #fff;
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  
  &:hover {
    background-color: #383838;
    cursor: pointer;
  }
`;

// Ajout des nouveaux styled components pour les compteurs
const StatsContainer = styled(Box)`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

const StatCard = styled(Paper)`
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.variant === 'success' ? 'rgba(76, 175, 80, 0.1)' : 'rgba(244, 67, 54, 0.1)'};
  border: 1px solid ${props => props.variant === 'success' ? 'rgba(76, 175, 80, 0.3)' : 'rgba(244, 67, 54, 0.3)'};

  .stat-value {
    font-size: 2rem;
    font-weight: bold;
    color: ${props => props.variant === 'success' ? '#66bb6a' : '#ff4444'};
  }

  .stat-label {
    color: ${props => props.variant === 'success' ? '#81c784' : '#ef9a9a'};
    font-size: 0.9rem;
    text-align: center;
  }
`;

const StyledPagination = styled(Stack)`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  
  .MuiPagination-ul {
    .MuiPaginationItem-root {
      color: #fff;
      
      &.Mui-selected {
        background-color: rgba(144, 202, 249, 0.2);
      }
    }
  }
`;

const SearchBar = styled(Box)`
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
  align-items: center;
  
  .MuiTextField-root {
    flex: 1;
    
    .MuiInputBase-root {
      background-color: rgba(255, 255, 255, 0.05);
      color: #fff;
      
      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }
      
      &.Mui-focused {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    
    .MuiInputLabel-root {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

const SecuritySection = styled(Box)`
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SecurityGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
    margin-top: 16px;
`;

const SecurityItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: ${props => props.active ? 'rgba(102, 187, 106, 0.1)' : 'rgba(255, 107, 107, 0.1)'};
    border: 1px solid ${props => props.active ? 'rgba(102, 187, 106, 0.3)' : 'rgba(255, 107, 107, 0.3)'};
    border-radius: 8px;

    .security-info {
        .title {
            font-size: 0.9rem;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 4px;
        }
        .value {
            font-size: 1rem;
            font-weight: 500;
            color: ${props => props.active ? '#66bb6a' : '#ff6b6b'};
        }
    }

    .icon {
        font-size: 1.5rem;
        color: ${props => props.active ? '#66bb6a' : '#ff6b6b'};
    }
`;

const StatusBar = styled(Box)`
  display: flex;
  gap: 12px;
  margin-top: 16px;
`;

const StatusItem = styled(Paper)`
  flex: 1;
  padding: 12px 16px;
  background: ${props => props.status === 'success' ?
    'linear-gradient(135deg, rgba(102, 187, 106, 0.1) 0%, rgba(102, 187, 106, 0.2) 100%)' :
    'linear-gradient(135deg, rgba(244, 67, 54, 0.1) 0%, rgba(244, 67, 54, 0.2) 100%)'
  };
  border: 1px solid ${props => props.status === 'success' ?
    'rgba(102, 187, 106, 0.3)' :
    'rgba(244, 67, 54, 0.3)'
  };
  border-radius: 8px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .title {
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 4px;
  }

  .value {
    font-size: 1.1rem;
    font-weight: 600;
    color: ${props => props.status === 'success' ? '#66bb6a' : '#f44336'};
    letter-spacing: 0.5px;
  }
`;

const SecurityScoreIndicator = styled(Box)`
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
  background: ${props => {
    if (props.score >= 80) return 'rgba(102, 187, 106, 0.1)';
    if (props.score >= 50) return 'rgba(255, 152, 0, 0.1)';
    return 'rgba(244, 67, 54, 0.1)';
  }};
  border: 1px solid ${props => {
    if (props.score >= 80) return 'rgba(102, 187, 106, 0.3)';
    if (props.score >= 50) return 'rgba(255, 152, 0, 0.3)';
    return 'rgba(244, 67, 54, 0.3)';
  }};
  
  .score-value {
    font-size: 2rem;
    font-weight: bold;
    color: ${props => {
      if (props.score >= 80) return '#66bb6a';
      if (props.score >= 50) return '#ffa726';
      return '#f44336';
    }};
    text-align: center;
    margin-bottom: 8px;
  }
  
  .score-label {
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9rem;
  }
`;

const SkedAC = () => {
    const { userToken } = useParams();
    const [user, setUser] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [screenshots, setScreenshots] = useState([]);
    const [networkData, setNetworkData] = useState([]);
    const [processData, setProcessData] = useState([]);
    const [controllersData, setControllersData] = useState([]);
    const [hardwareDetails, setHardwareDetails] = useState(null);
    const [loadingImages, setLoadingImages] = useState({});
    const [accounts, setAccounts] = useState([]);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [processSearch, setProcessSearch] = useState('');
    const [controllerSearch, setControllerSearch] = useState('');
    const [showIPs, setShowIPs] = useState({});
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState('');
    const [softwareSearch, setSoftwareSearch] = useState('');
    const [startupSearch, setStartupSearch] = useState('');
    const [audioSearch, setAudioSearch] = useState('');
    const [monitorSearch, setMonitorSearch] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const userData = await loginWithToken(userToken);
                if (userData.status === 'error') {
                    alert('Token expiré');
                    window.location.href = 'https://app.sked.gg/';
                } else {
                    if(userData?.data?.user_admin === 0) {
                        window.location.href = 'https://app.sked.gg/';
                    } else {
                        const authorizedUsers = ['Benjxmin', 'Reborn', 'iKayzL'];
                        if (!authorizedUsers.includes(userData?.data?.user_nickname)) {
                            window.location.href = 'https://app.sked.gg/';
                        } else {
                            setUser(userData);
                        }
                    }
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
                window.location.href = 'https://app.sked.gg/';
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [userToken]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const formattedDate = selectedDate.format('YYYY-MM-DD');
            const response = await fetch(`https://arbitrage-api.sked.gg/sked-ac/users-by-date?date=${formattedDate}`);
            const data = await response.json();
            
            if (data.success) {
                setUsers(data.users);
                console.log('Détails des utilisateurs:', data.users);
                await Promise.all(data.users.map(user => fetchUserDetails(user.nickname)));
                
            } else {
                console.error('Erreur lors de la récupération des utilisateurs');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUserClick = (user) => {
        setSelectedUser(user);
        setOpenDialog(true);
        const formattedDate = selectedDate.format('YYYY-MM-DD');
        loadHardwareDetails(user.nickname, formattedDate);
        loadDetailedData(user.nickname, formattedDate);
    };

    const fetchUserDetails = async (nickname) => {
        setIsRefreshing(true);
        try {
            const response = await fetch('https://arbitrage-api.sked.gg/sked-ac/user/find', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ nickname: nickname })
            });
            
            const data = await response.json();
            
            if (data.success) {
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    [nickname]: {
                        ...data.user
                    }
                }));
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des détails:', error.message);
        } finally {
            setIsRefreshing(false);
        }
    };

    const loadDetailedData = async (nickname, date) => {
        try {
            const screenshotsResponse = await fetch(`https://arbitrage-api.sked.gg/sked-ac/screenshots?date=${date}&nickname=${nickname}`);
            const screenshotsData = await screenshotsResponse.json();
            
            if (screenshotsData.success) {
                const formattedScreenshots = screenshotsData.screenshots
                    .map(screenshot => ({
                        ...screenshot,
                        thumbnailUrl: `https://arbitrage-api.sked.gg${screenshot.url}?size=thumbnail`,
                        fullUrl: `https://arbitrage-api.sked.gg${screenshot.url}`,
                        formattedTime: new Date(screenshot.timestamp * 1000).toLocaleTimeString('fr-FR')
                    }))
                    .sort((a, b) => b.timestamp - a.timestamp); // Tri par timestamp décroissant
                setScreenshots(formattedScreenshots);
            }

            // Processus
            const processesResponse = await fetch(`https://arbitrage-api.sked.gg/sked-ac/processes?date=${date}&nickname=${nickname}`);
            const processesData = await processesResponse.json();
            if (processesData.success) {
                setProcessData(processesData.processes);
            }

            // Réseau
            const networkResponse = await fetch(`https://arbitrage-api.sked.gg/sked-ac/network?date=${date}&nickname=${nickname}`);
            const networkData = await networkResponse.json();
            if (networkData.success) {
                setNetworkData(networkData.network);
            }

            // Périphériques
            const devicesResponse = await fetch(`https://arbitrage-api.sked.gg/sked-ac/devices?date=${date}&nickname=${nickname}`);
            const devicesData = await devicesResponse.json();
            if (devicesData.success) {
                setControllersData(devicesData.devices);
            }

        } catch (error) {
            console.error('Erreur lors du chargement des données détaillées:', error);
        }
    };

    const loadHardwareDetails = async (nickname, date) => {
        try {
            const response = await fetch(`https://arbitrage-api.sked.gg/sked-ac/hardware-details?date=${date}&nickname=${nickname}`);
            const data = await response.json();
            if (data.success) {
                setHardwareDetails(data.hardwareInfo);
            }
        } catch (error) {
            console.error('Erreur lors du chargement des détails hardware:', error);
        }
    };

    const handleBanUser = async () => {
        try {
            const endpoint = userDetails[selectedUser.nickname]?.banned 
                ? 'https://arbitrage-api.sked.gg/sked-ac/unban-user'
                : 'https://arbitrage-api.sked.gg/sked-ac/ban-user';

            console.log('État actuel banned:', userDetails[selectedUser.nickname]?.banned);
            console.log('Endpoint utilisé:', endpoint);
            console.log('IP MAC envoyée:', userDetails[selectedUser.nickname]?.ipMac);

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    ipMac: userDetails[selectedUser.nickname]?.ipMac
                })
            });
            
            const data = await response.json();
            console.log('Réponse du serveur:', data);
            
            if (data.success) {
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    [selectedUser.nickname]: {
                        ...prevDetails[selectedUser.nickname],
                        banned: !prevDetails[selectedUser.nickname].banned
                    }
                }));
            } else {
                console.error('Erreur lors de l\'opération:', data.message);
            }
        } catch (error) {
            console.error('Erreur lors de l\'opération:', error);
        }
    };

    const handleResetAllCharlieDelta = async () => {
        try {
            const usersToReset = users.filter(user => 
                userDetails[user.nickname] && 
                userDetails[user.nickname].charlieDelta
            );

            if (usersToReset.length === 0) {
                return;
            }

            // Afficher une confirmation
            if (!window.confirm(`Voulez-vous vraiment réinitialiser l'isolation pour ${usersToReset.length} joueurs ?`)) {
                return;
            }

            setLoading(true);

            // Réinitialiser pour chaque utilisateur
            const promises = usersToReset.map(user => 
                fetch('https://arbitrage-api.sked.gg/sked-ac/reset-charlie-delta', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 
                        nickname: user.nickname 
                    })
                })
            );

            await Promise.all(promises);

            // Mettre à jour le state local
            const updatedUserDetails = { ...userDetails };
            usersToReset.forEach(user => {
                updatedUserDetails[user.nickname] = {
                    ...updatedUserDetails[user.nickname],
                    charlieDelta: false,
                    reactivatedAt: null
                };
            });
            setUserDetails(updatedUserDetails);

        } catch (error) {
            console.error('Erreur lors de la réinitialisation globale:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleExportUsers = () => {
        if (!users.length || !selectedDate) return;

        let exportContent = `Export des joueurs du ${selectedDate.format('DD/MM/YYYY')}\n\n`;

        // Grouper les utilisateurs par IP MAC
        const usersByIpMac = users.reduce((acc, user) => {
            const userDetail = userDetails[user.nickname];
            if (!userDetail) return acc;

            const ipMac = userDetail.ipMac;
            if (!acc[ipMac]) {
                acc[ipMac] = [];
            }
            acc[ipMac].push(user);
            return acc;
        }, {});

        // Générer le contenu pour chaque groupe d'IP MAC
        Object.entries(usersByIpMac).forEach(([ipMac, groupUsers]) => {
            const firstUser = groupUsers[0];
            const detail = userDetails[firstUser.nickname];
            
            exportContent += `IP MAC: ${ipMac}\n`;
            exportContent += `IP Public: ${detail.ipPublic}\n`;
            exportContent += `Force Playing: ${detail.forcePlaying ? 'OUI' : 'NON'}\n`;
            exportContent += `Isolation du noyau: ${detail.charlieDelta ? 'DÉSACTIVÉE' : 'ACTIVÉE'}\n`;
            
            if (groupUsers.length > 1) {
                exportContent += 'Pseudos associés:\n';
                groupUsers.forEach(user => {
                    exportContent += `- ${user.nickname}\n`;
                });
            } else {
                exportContent += `Pseudo: ${firstUser.nickname}\n`;
            }
            
            exportContent += '\n-------------------\n\n';
        });

        // Créer et télécharger le fichier
        const blob = new Blob([exportContent], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `export_joueurs_${selectedDate.format('YYYY-MM-DD')}.txt`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const filteredProcessData = processData.filter(process => 
        Object.values(process).some(value => 
            String(value).toLowerCase().includes(processSearch.toLowerCase())
        )
    );

    const filteredControllerData = controllersData.filter(controller => 
        Object.values(controller).some(value => 
            String(value).toLowerCase().includes(controllerSearch.toLowerCase())
        )
    );

    const handleCopyIP = (ip) => {
        navigator.clipboard.writeText(ip);
        // Optionnel : Ajouter un feedback visuel
    };

    const toggleShowIP = (ipType, nickname) => {
        setShowIPs(prev => ({
            ...prev,
            [nickname]: {
                ...prev[nickname],
                [ipType]: !prev[nickname]?.[ipType]
            }
        }));
    };

    const maskIP = (ip) => '•'.repeat(ip.length);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    // Filtrer les utilisateurs en fonction de la recherche
    const filteredUsers = users.filter(user => 
        user.nickname.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleResetKernelIsolation = async () => {
        try {
            const response = await fetch('https://arbitrage-api.sked.gg/sked-ac/reset-charlie-delta', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    nickname: selectedUser.nickname 
                })
            });

            const data = await response.json();
            
            if (data.success) {
                // Mise à jour locale des données
                setHardwareDetails(prev => ({
                    ...prev,
                    kernelIsolation: false
                }));
                
                // Mise à jour des détails utilisateur
                setUserDetails(prev => ({
                    ...prev,
                    [selectedUser.nickname]: {
                        ...prev[selectedUser.nickname],
                        charlieDelta: false,
                        reactivatedAt: null
                    }
                }));
            } else {
                console.error('Erreur lors de la réinitialisation:', data.message);
            }
        } catch (error) {
            console.error('Erreur lors de la réinitialisation:', error);
        }
    };

    // Ajouter cette fonction pour filtrer les logiciels
    const filteredSoftware = hardwareDetails?.InstalledSoftware 
        ? hardwareDetails.InstalledSoftware.filter(software =>
            String(software).toLowerCase().includes(softwareSearch.toLowerCase())
          )
        : [];

    // Ajouter cette fonction pour filtrer les programmes de démarrage
    const filteredStartupPrograms = hardwareDetails?.StartupPrograms 
        ? hardwareDetails.StartupPrograms.filter(program =>
            Object.values(program).some(value => 
                String(value).toLowerCase().includes(startupSearch.toLowerCase())
            )
          )
        : [];

    // Ajouter cette fonction pour filtrer les périphériques audio
    const filteredAudioDevices = hardwareDetails?.AudioDevices 
        ? hardwareDetails.AudioDevices.filter(device =>
            String(device).toLowerCase().includes(audioSearch.toLowerCase())
          )
        : [];

    const handleRefreshUserDetails = async () => {
        if (!selectedUser?.nickname || !selectedDate) return;
        
        setIsRefreshing(true);
        try {
            await fetchUserDetails(selectedUser.nickname);
            await loadHardwareDetails(selectedUser.nickname, selectedDate.format('YYYY-MM-DD'));
            await loadDetailedData(selectedUser.nickname, selectedDate.format('YYYY-MM-DD'));
        } catch (error) {
            console.error('Erreur lors du rafraîchissement des données:', error);
        } finally {
            setIsRefreshing(false);
        }
    };

    return user ? (
        <StyledContainer>
            <StyledPaper>
                <ActionContainer>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <DatePicker
                            label="Sélectionner une date"
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                    </LocalizationProvider>
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={fetchUsers}
                        disabled={!selectedDate || loading}
                    >
                        {loading ? 'Chargement...' : 'Afficher les utilisateurs'}
                    </Button>
                    <Button 
                        variant="contained" 
                        color="warning"
                        onClick={handleExportUsers}
                        disabled={!users.length || !selectedDate}
                    >
                        Exporter les utilisateurs
                    </Button>
                </ActionContainer>

                {users.length > 0 && (
                    <StatsContainer>
                        <StatCard variant="success">
                            <span className="stat-value">
                                {users.length}
                            </span>
                            <span className="stat-label">Joueurs connectés</span>
                        </StatCard>
                        <StatCard variant="success">
                            <span className="stat-value">
                                {users.filter(user => 
                                    userDetails[user.nickname] && 
                                    !userDetails[user.nickname].forcePlaying
                                ).length}
                            </span>
                            <span className="stat-label">Joueurs sans Force Playing</span>
                        </StatCard>
                        <StatCard variant="error">
                            <span className="stat-value">
                                {users.filter(user => 
                                    userDetails[user.nickname] && 
                                    userDetails[user.nickname].forcePlaying
                                ).length}
                            </span>
                            <span className="stat-label">Joueurs avec Force Playing</span>
                        </StatCard>
                    </StatsContainer>
                )}

                {loading && (
                    <LoaderContainer>
                        <CircularProgress />
                    </LoaderContainer>
                )}

                <SearchBar>
                    <TextField
                        fullWidth
                        label="Rechercher un joueur..."
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </SearchBar>

                <UserGrid>
                    {filteredUsers
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((user, index) => {
                            const userDetail = userDetails[user.nickname];
                            if (!userDetail) return null;

                            const isFirstWithThisIpMac = users.findIndex(u => 
                                userDetails[u.nickname]?.ipMac === userDetail.ipMac
                            ) === users.indexOf(user);

                            if (!isFirstWithThisIpMac) return null;

                            const relatedUsers = users.filter(u => 
                                userDetails[u.nickname]?.ipMac === userDetail.ipMac
                            );

                            return (
                                <UserCard 
                                    key={index}
                                    onClick={() => handleUserClick(user)}
                                >
                                    <Box sx={{ 
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1
                                    }}>
                                        <Box sx={{ 
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: 1,
                                            marginBottom: '8px'
                                        }}>
                                            <PlayerName 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleUserClick(user);
                                                }}
                                            >
                                                {user.nickname}
                                            </PlayerName>
                                            {relatedUsers.length > 1 && relatedUsers
                                                .filter(ru => ru.nickname !== user.nickname)
                                                .map((ru, index) => (
                                                    <PlayerName 
                                                        key={index}
                                                        sx={{ 
                                                            backgroundColor: '#3d2c2c',
                                                            borderColor: '#544',
                                                            fontSize: '1rem',
                                                            padding: '6px 12px'
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleUserClick(ru);
                                                        }}
                                                    >
                                                        {ru.nickname}
                                                    </PlayerName>
                                                ))
                                            }
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                            <StatusChip 
                                                label={`IP MAC: ${showIPs[user.nickname]?.mac 
                                                    ? userDetail.ipMac 
                                                    : maskIP(userDetail.ipMac)}`}
                                                className="ip-info"
                                                size="small"
                                                deleteIcon={
                                                    <Box sx={{ display: 'flex', gap: '4px' }}>
                                                        <Button
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                toggleShowIP('mac', user.nickname);
                                                            }}
                                                            sx={{ 
                                                                minWidth: 'auto',
                                                                padding: '2px 4px',
                                                                fontSize: '0.7rem',
                                                                color: 'inherit'
                                                            }}
                                                        >
                                                            {showIPs[user.nickname]?.mac ? 'Masquer' : 'Afficher'}
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleCopyIP(userDetail.ipMac);
                                                            }}
                                                            sx={{ 
                                                                minWidth: 'auto',
                                                                padding: '2px 4px',
                                                                fontSize: '0.7rem',
                                                                color: 'inherit'
                                                            }}
                                                        >
                                                            Copier
                                                        </Button>
                                                    </Box>
                                                }
                                                onDelete={() => {}} // Nécessaire pour afficher le deleteIcon
                                            />
                                            <StatusChip 
                                                label={`IP Public: ${showIPs[user.nickname]?.public 
                                                    ? userDetail.ipPublic 
                                                    : maskIP(userDetail.ipPublic)}`}
                                                className="ip-info"
                                                size="small"
                                                deleteIcon={
                                                    <Box sx={{ display: 'flex', gap: '4px' }}>
                                                        <Button
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                toggleShowIP('public', user.nickname);
                                                            }}
                                                            sx={{ 
                                                                minWidth: 'auto',
                                                                padding: '2px 4px',
                                                                fontSize: '0.7rem',
                                                                color: 'inherit'
                                                            }}
                                                        >
                                                            {showIPs[user.nickname]?.public ? 'Masquer' : 'Afficher'}
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleCopyIP(userDetail.ipPublic);
                                                            }}
                                                            sx={{ 
                                                                minWidth: 'auto',
                                                                padding: '2px 4px',
                                                                fontSize: '0.7rem',
                                                                color: 'inherit'
                                                            }}
                                                        >
                                                            Copier
                                                        </Button>
                                                    </Box>
                                                }
                                                onDelete={() => {}}
                                            />
                                            <StatusChip 
                                                label={`Banni: ${userDetail.banned ? 'OUI' : 'NON'}`}
                                                className={userDetail.banned ? 'banned' : 'normal-status'}
                                                size="small"
                                            />
                                            <StatusChip 
                                                label={`Force Playing: ${userDetail.forcePlaying ? 'OUI' : 'NON'}`}
                                                className={userDetail.forcePlaying ? 'force-playing' : 'normal-status'}
                                                size="small"
                                            />
                                        </Box>
                                    </Box>
                                </UserCard>
                            );
                        })}
                </UserGrid>
                
                <StyledPagination>
                    <Pagination 
                        count={Math.ceil(filteredUsers.length / itemsPerPage)}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </StyledPagination>
            </StyledPaper>

            <FullScreenDialog 
                open={openDialog} 
                onClose={() => setOpenDialog(false)}
                fullScreen
            >
                {isRefreshing && (
                    <LinearProgress 
                        sx={{ 
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 9999
                        }} 
                    />
                )}
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Détails de l'utilisateur: {selectedUser?.nickname}</Typography>
                        <Box display="flex" gap={2}>
                            <Button 
                                variant="outlined"
                                color="primary"
                                onClick={handleRefreshUserDetails}
                                startIcon={<RefreshIcon />}
                            >
                                Rafraîchir
                            </Button>
                            <Button 
                                onClick={() => setOpenDialog(false)}
                                color="inherit"
                            >
                                Fermer
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {selectedUser && userDetails[selectedUser.nickname] && (
                        <>
                            <StatusBar>
                                <StatusItem status={userDetails[selectedUser?.nickname]?.banned ? 'error' : 'success'}>
                                    <div className="title">Statut du compte</div>
                                    <div className="value">
                                        {userDetails[selectedUser?.nickname]?.banned ? 'BANNI' : 'ACTIF'}
                                    </div>
                                </StatusItem>
                                
                                <StatusItem status={userDetails[selectedUser?.nickname]?.forcePlaying ? 'error' : 'success'}>
                                    <div className="title">Force Playing</div>
                                    <div className="value">
                                        {userDetails[selectedUser?.nickname]?.forcePlaying ? 'OUI' : 'NON'}
                                    </div>
                                </StatusItem>
                            </StatusBar>

                            <IPSection>
                                <IPCard elevation={0}>
                                    <span className="ip-label">IP MAC</span>
                                    <span className="ip-value">
                                        {showIPs[selectedUser.nickname]?.mac 
                                            ? userDetails[selectedUser.nickname].ipMac 
                                            : maskIP(userDetails[selectedUser.nickname].ipMac)}
                                        <div className="ip-buttons">
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => toggleShowIP('mac', selectedUser.nickname)}
                                                sx={{ 
                                                    minWidth: 'auto',
                                                    padding: '4px 8px',
                                                    fontSize: '0.75rem'
                                                }}
                                            >
                                                {showIPs[selectedUser.nickname]?.mac ? 'Masquer' : 'Afficher'}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleCopyIP(userDetails[selectedUser.nickname].ipMac)}
                                                sx={{ 
                                                    minWidth: 'auto',
                                                    padding: '4px 8px',
                                                    fontSize: '0.75rem'
                                                }}
                                            >
                                                Copier
                                            </Button>
                                        </div>
                                    </span>
                                    <BanButton 
                                        variant="contained"
                                        size="small"
                                        color={userDetails[selectedUser.nickname].banned ? "success" : "error"}
                                        onClick={handleBanUser}
                                    >
                                        {userDetails[selectedUser.nickname].banned ? 'Débannir' : 'Bannir'}
                                    </BanButton>
                                </IPCard>
                                <IPCard elevation={0}>
                                    <span className="ip-label">IP Public</span>
                                    <span className="ip-value">
                                        {showIPs[selectedUser.nickname]?.public 
                                            ? userDetails[selectedUser.nickname].ipPublic 
                                            : maskIP(userDetails[selectedUser.nickname].ipPublic)}
                                        <div className="ip-buttons">
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => toggleShowIP('public', selectedUser.nickname)}
                                                sx={{ 
                                                    minWidth: 'auto',
                                                    padding: '4px 8px',
                                                    fontSize: '0.75rem'
                                                }}
                                            >
                                                {showIPs[selectedUser.nickname]?.public ? 'Masquer' : 'Afficher'}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleCopyIP(userDetails[selectedUser.nickname].ipPublic)}
                                                sx={{ 
                                                    minWidth: 'auto',
                                                    padding: '4px 8px',
                                                    fontSize: '0.75rem'
                                                }}
                                            >
                                                Copier
                                            </Button>
                                        </div>
                                    </span>
                                </IPCard>
                            </IPSection>
                            
                            <SecuritySection>
                                <Typography variant="subtitle1" sx={{ 
                                    color: '#90caf9', 
                                    fontWeight: 500,
                                    fontSize: '1rem',
                                    marginBottom: '4px'
                                }}>
                                    Sécurité Windows
                                </Typography>
                                
                                {hardwareDetails?.security_info?.incompatible_drivers?.length > 0 && (
                                    <Box sx={{
                                        backgroundColor: 'rgba(244, 67, 54, 0.1)',
                                        border: '1px solid rgba(244, 67, 54, 0.3)',
                                        borderRadius: '4px',
                                        padding: '12px',
                                        marginBottom: '16px'
                                    }}>
                                        <Typography sx={{
                                            color: '#f44336',
                                            fontWeight: 500,
                                            marginBottom: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px'
                                        }}>
                                            ⚠️ Pilotes incompatibles détectés
                                        </Typography>
                                        <Box sx={{
                                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            fontFamily: 'monospace',
                                            fontSize: '0.9rem'
                                        }}>
                                            {hardwareDetails.security_info.incompatible_drivers.map((driver, index) => (
                                                <Typography key={index} sx={{
                                                    color: 'rgba(255, 255, 255, 0.8)',
                                                    marginBottom: '4px'
                                                }}>
                                                    {driver}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Box>
                                )}

                                <SecurityScoreIndicator score={hardwareDetails?.security_info?.confidence_score || 0}>
                                  <div className="score-value">
                                    {hardwareDetails?.security_info?.confidence_score || 0}%
                                  </div>
                                  <div className="score-label">
                                    Score de détection
                                  </div>
                                </SecurityScoreIndicator>

                                <SecurityGrid>
                                    <SecurityItem active={!hardwareDetails?.security_info?.incompatible_drivers?.length}>
                                        <div className="security-info">
                                            <div className="title">Pilotes compatibles</div>
                                            <div className="value">
                                                {hardwareDetails?.security_info?.incompatible_drivers?.length ? 'Non' : 'Oui'}
                                            </div>
                                        </div>
                                        <div className="icon">
                                            {!hardwareDetails?.security_info?.incompatible_drivers?.length ? '✓' : '✗'}
                                        </div>
                                    </SecurityItem>

                                    <SecurityItem active={hardwareDetails?.security_info?.memory_protection}>
                                        <div className="security-info">
                                            <div className="title">Protection mémoire</div>
                                            <div className="value">
                                                {hardwareDetails?.security_info?.memory_protection ? 'Activée' : 'Désactivée'}
                                            </div>
                                        </div>
                                        <div className="icon">
                                            {hardwareDetails?.security_info?.memory_protection ? '✓' : '✗'}
                                        </div>
                                    </SecurityItem>

                                    <SecurityItem active={hardwareDetails?.security_info?.secure_boot}>
                                        <div className="security-info">
                                            <div className="title">Secure Boot</div>
                                            <div className="value">
                                                {hardwareDetails?.security_info?.secure_boot ? 'Activé' : 'Désactivé'}
                                            </div>
                                        </div>
                                        <div className="icon">
                                            {hardwareDetails?.security_info?.secure_boot ? '✓' : '✗'}
                                        </div>
                                    </SecurityItem>

                                    <SecurityItem active={hardwareDetails?.security_info?.virtualization}>
                                        <div className="security-info">
                                            <div className="title">Virtualisation</div>
                                            <div className="value">
                                                {hardwareDetails?.security_info?.virtualization ? 'Activée' : 'Désactivée'}
                                            </div>
                                        </div>
                                        <div className="icon">
                                            {hardwareDetails?.security_info?.virtualization ? '✓' : '✗'}
                                        </div>
                                    </SecurityItem>
                                </SecurityGrid>
                            </SecuritySection>
                        </>
                    )}

                    <HardwareSection elevation={3}>
                        <Typography 
                            variant="h6" 
                            gutterBottom 
                            sx={{ 
                                fontWeight: 500,
                                color: '#fff',
                                borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                                paddingBottom: '12px'
                            }}
                        >
                            Informations système
                        </Typography>
                        {hardwareDetails ? (
                            <HardwareGrid>
                                <HardwareItem>
                                    <h4>Système</h4>
                                    <p>OS: {hardwareDetails.OSVersion || 'No data'}</p>
                                    <p>Utilisateur: {hardwareDetails.Username || 'No data'}</p>
                                    <p>PC: {hardwareDetails.ComputerName || 'No data'}</p>
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Processeur</h4>
                                    <p>{hardwareDetails.Processor || 'No data'}</p>
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>RAM</h4>
                                    <p>{hardwareDetails.RAM || 'No data'}</p>
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Carte graphique</h4>
                                    {hardwareDetails.GraphicsCards?.length > 0 
                                        ? hardwareDetails.GraphicsCards.map((card, index) => (
                                            <p key={index}>{card}</p>
                                        ))
                                        : <p>No data</p>
                                    }
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Stockage</h4>
                                    {hardwareDetails.DiskDrives?.length > 0 
                                        ? hardwareDetails.DiskDrives.map((drive, index) => (
                                            <p key={index}>{drive}</p>
                                        ))
                                        : <p>No data</p>
                                    }
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Carte mère</h4>
                                    <p>{hardwareDetails.Motherboard || 'No data'}</p>
                                    <p>{hardwareDetails.BIOSInfo || 'No data'}</p>
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Réseau</h4>
                                    {hardwareDetails.NetworkAdapters?.length > 0 
                                        ? hardwareDetails.NetworkAdapters.map((adapter, index) => (
                                            <p key={index}>{adapter}</p>
                                        ))
                                        : <p>No data</p>
                                    }
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Antivirus</h4>
                                    <p>{hardwareDetails.AntivirusInfo || 'No data'}</p>
                                </HardwareItem>
                            </HardwareGrid>
                        ) : (
                            <Box display="flex" justifyContent="center" padding={4}>
                                <CircularProgress size={32} sx={{ color: '#90caf9' }} />
                            </Box>
                        )}
                    </HardwareSection>

                    <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
                        <Tab label="Captures d'écran" />
                        <Tab label="Périphériques Audio" />
                        <Tab label="Processus" />
                        <Tab label="Périphériques" />
                        <Tab label="Logiciels installés" />
                        <Tab label="Programmes de démarrage" />
                        <Tab label="Moniteurs" />
                    </Tabs>

                    {activeTab === 0 && (
                        <>
                            <StyledImageList 
                                gap={16} 
                                sx={{ 
                                    maxHeight: 'calc(100vh - 300px)',
                                    gridTemplateColumns: 'repeat(auto-fill, 140px) !important'
                                }}
                            >
                                {screenshots.map((screenshot, index) => (
                                    <StyledImageListItem 
                                        key={index}
                                        onClick={() => setSelectedImage(screenshot.fullUrl)}
                                    >
                                        <img
                                            src={screenshot.thumbnailUrl}
                                            alt={`Capture d'écran ${screenshots.length - index}`}
                                            loading="lazy"
                                            style={{ 
                                                width: '140px',
                                                height: '180px',
                                                objectFit: 'cover'
                                            }}
                                        />
                                        <div className="time-overlay">
                                            {screenshot.formattedTime}
                                        </div>
                                    </StyledImageListItem>
                                ))}
                            </StyledImageList>

                            <ImageModal
                                open={!!selectedImage}
                                onClose={() => setSelectedImage(null)}
                            >
                                <Box sx={{
                                    position: 'relative',
                                    maxWidth: '90vw',
                                    maxHeight: '90vh',
                                }}>
                                    {!loadingImages[selectedImage] && (
                                        <Box sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            zIndex: 1
                                        }}>
                                            <CircularProgress size={40} />
                                        </Box>
                                    )}
                                    <img 
                                        src={selectedImage} 
                                        alt="Capture d'écran agrandie"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '90vh',
                                            objectFit: 'contain',
                                            opacity: loadingImages[selectedImage] ? 1 : 0,
                                            transition: 'opacity 0.3s'
                                        }}
                                        onLoad={() => {
                                            setLoadingImages(prev => ({
                                                ...prev,
                                                [selectedImage]: true
                                            }));
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => setSelectedImage(null)}
                                        sx={{
                                            position: 'absolute',
                                            top: -40,
                                            right: -40,
                                            color: 'white'
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </ImageModal>
                        </>
                    )}

                    {activeTab === 1 && (
                        <>
                            <SearchContainer>
                                <SearchInput
                                    placeholder="Rechercher dans les périphériques audio..."
                                    value={audioSearch}
                                    onChange={(e) => setAudioSearch(e.target.value)}
                                />
                            </SearchContainer>
                            
                            <DataSection>
                                {hardwareDetails?.AudioDevices ? (
                                    filteredAudioDevices.length > 0 ? (
                                        filteredAudioDevices.map((device, index) => (
                                            <div key={index} className="data-item">
                                                <div className="data-row">
                                                    <span className="data-label">Nom:</span>
                                                    <span className="data-value">{device}</span>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <Box display="flex" justifyContent="center" padding={4}>
                                            <Typography color="text.secondary">
                                                Aucun périphérique audio ne correspond à votre recherche
                                            </Typography>
                                        </Box>
                                    )
                                ) : (
                                    <Box display="flex" justifyContent="center" padding={4}>
                                        <Typography color="text.secondary">
                                            Aucun périphérique audio trouvé
                                        </Typography>
                                    </Box>
                                )}
                            </DataSection>
                        </>
                    )}

                    {activeTab === 2 && (
                        <>
                            <SearchContainer>
                                <SearchInput
                                    placeholder="Rechercher dans les processus..."
                                    value={processSearch}
                                    onChange={(e) => setProcessSearch(e.target.value)}
                                />
                            </SearchContainer>
                            
                            {/* Affichage des processus suspects */}
                            {filteredProcessData && filteredProcessData.length > 0 && 
                             filteredProcessData.some(process => isItemSuspicious(process, 'process')) && (
                                <SuspiciousSection>
                                    <SuspiciousHeader>
                                        Processus suspects détectés
                                    </SuspiciousHeader>
                                    {filteredProcessData
                                        .filter(process => isItemSuspicious(process, 'process'))
                                        .map((process, index) => (
                                            <div key={index} className="data-item">
                                                {Object.entries(process).map(([key, value]) => {
                                                    const valueStr = String(value);
                                                    const isSuspiciousValue = SUSPICIOUS_KEYWORDS.process.some(
                                                        keyword => valueStr.toLowerCase().includes(keyword.toLowerCase())
                                                    );
                                                    return (
                                                        <div key={key} className="data-row">
                                                            <span className="data-label">{key}:</span>
                                                            {isSuspiciousValue ? (
                                                                <SuspiciousHighlight>{valueStr}</SuspiciousHighlight>
                                                            ) : (
                                                                <span className="data-value">{valueStr}</span>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ))}
                                </SuspiciousSection>
                            )}
                            
                            {/* Affichage des processus normaux */}
                            <DataSection>
                                {filteredProcessData && filteredProcessData.length > 0 ? (
                                    filteredProcessData
                                        .filter(process => !isItemSuspicious(process, 'process'))
                                        .map((process, index) => (
                                            <div key={index} className="data-item">
                                                {Object.entries(process).map(([key, value]) => (
                                                    <div key={key} className="data-row">
                                                        <span className="data-label">{key}:</span>
                                                        <span className="data-value">{String(value) || 'No data'}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                ) : (
                                    <Box display="flex" justifyContent="center" padding={4}>
                                        <Typography color="text.secondary">
                                            No data
                                        </Typography>
                                    </Box>
                                )}
                            </DataSection>
                        </>
                    )}

                    {activeTab === 3 && (
                        <>
                            <SearchContainer>
                                <SearchInput
                                    placeholder="Rechercher dans les périphériques..."
                                    value={controllerSearch}
                                    onChange={(e) => setControllerSearch(e.target.value)}
                                />
                            </SearchContainer>
                            
                            {/* Affichage des contrôleurs suspects */}
                            {filteredControllerData.some(device => isControllerSuspicious(device)) && (
                                <SuspiciousSection>
                                    <SuspiciousHeader>
                                        Périphériques suspects détectés
                                    </SuspiciousHeader>
                                    {filteredControllerData
                                        .filter(device => isControllerSuspicious(device))
                                        .map((device, index) => (
                                            <div key={index} className="data-item">
                                                {Object.entries(device).map(([key, value]) => {
                                                    const valueStr = String(value);
                                                    const isSuspiciousValue = SUSPICIOUS_KEYWORDS.controllers.some(
                                                        keyword => valueStr.toLowerCase().includes(keyword.toLowerCase())
                                                    );
                                                    return (
                                                        <div key={key} className="data-row">
                                                            <span className="data-label">{key}:</span>
                                                            {isSuspiciousValue ? (
                                                                <SuspiciousHighlight>{valueStr}</SuspiciousHighlight>
                                                            ) : (
                                                                <span className="data-value">{valueStr}</span>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ))}
                                </SuspiciousSection>
                            )}
                            
                            {/* Affichage des contrôleurs normaux */}
                            <DataSection>
                                {filteredControllerData.length > 0 ? (
                                    filteredControllerData
                                        .filter(device => !isControllerSuspicious(device))
                                        .map((device, index) => (
                                            <div key={index} className="data-item">
                                                {Object.entries(device).map(([key, value]) => (
                                                    <div key={key} className="data-row">
                                                        <span className="data-label">{key}:</span>
                                                        <span className="data-value">{String(value) || 'No data'}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                ) : (
                                    <Box display="flex" justifyContent="center" padding={4}>
                                        <Typography color="text.secondary">
                                            No data
                                        </Typography>
                                    </Box>
                                )}
                            </DataSection>
                        </>
                    )}

                    {activeTab === 4 && (
                        <>
                            <SearchContainer>
                                <SearchInput
                                    placeholder="Rechercher dans les logiciels installés..."
                                    value={softwareSearch}
                                    onChange={(e) => setSoftwareSearch(e.target.value)}
                                />
                            </SearchContainer>
                            
                            <DataSection>
                                {hardwareDetails?.InstalledSoftware ? (
                                    filteredSoftware.length > 0 ? (
                                        filteredSoftware.map((softwareItem, index) => {
                                            const softwareDetails = {
                                                version: softwareItem.version || softwareItem,
                                                publisher: softwareItem.publisher || softwareItem,
                                                installDate: softwareItem.installDate || softwareItem
                                            };
                                            return (
                                                <div key={index} className="data-item">
                                                    <div className="data-row">
                                                        <span className="data-label">Nom:</span>
                                                        <span className="data-value">{softwareItem}</span>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <Box display="flex" justifyContent="center" padding={4}>
                                            <Typography color="text.secondary">
                                                Aucun logiciel ne correspond à votre recherche
                                            </Typography>
                                        </Box>
                                    )
                                ) : (
                                    <Box display="flex" justifyContent="center" padding={4}>
                                        <Typography color="text.secondary">
                                            Aucun logiciel installé trouvé
                                        </Typography>
                                    </Box>
                                )}
                            </DataSection>
                        </>
                    )}

                    {activeTab === 5 && (
                        <>

                            <DataSection>
                                {hardwareDetails?.StartupPrograms ? (
                                    filteredStartupPrograms.length > 0 ? (
                                        filteredStartupPrograms.map((program, index) => (
                                            <div key={index} className="data-item">
                                                <div className="data-row">
                                                    <span className="data-label">Nom:</span>
                                                    <span className="data-value">{program}</span>
                                                </div>
                                                <div className="data-row">
                                                    <span className="data-label">État:</span>
                                                    <span className="data-value" style={{
                                                        color: program.enabled ? '#66bb6a' : '#ff6b6b'
                                                    }}>
                                                        {program.enabled ? 'Activé' : 'Désactivé'}
                                                    </span>
                                                </div>
                                                {program.publisher && (
                                                    <div className="data-row">
                                                        <span className="data-label">Éditeur:</span>
                                                        <span className="data-value">{program}</span>
                                                    </div>
                                                )}
                                                {program.description && (
                                                    <div className="data-row">
                                                        <span className="data-label">Description:</span>
                                                        <span className="data-value">{program}</span>
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <Box display="flex" justifyContent="center" padding={4}>
                                            <Typography color="text.secondary">
                                                Aucun programme de démarrage ne correspond à votre recherche
                                            </Typography>
                                        </Box>
                                    )
                                ) : (
                                    <Box display="flex" justifyContent="center" padding={4}>
                                        <Typography color="text.secondary">
                                            Aucun programme de démarrage trouvé
                                        </Typography>
                                    </Box>
                                )}
                            </DataSection>
                        </>
                    )}

                    {activeTab === 6 && (
                        <>
    
                            <DataSection>
                                {hardwareDetails?.MonitorInfo?.length > 0 ? (
                                    hardwareDetails.MonitorInfo.map((monitor, index) => (
                                        <div key={index} className="data-item">
                                            <div className="data-row">
                                                <span className="data-label">Nom:</span>
                                                <span className="data-value">{monitor || 'No data'}</span>
                                            </div>
                                            {monitor?.technology && (
                                                <div className="data-row">
                                                    <span className="data-label">Technologie:</span>
                                                    <span className="data-value">{monitor.technology || 'No data'}</span>
                                                </div>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <Box display="flex" justifyContent="center" padding={4}>
                                        <Typography color="text.secondary">
                                            No data
                                        </Typography>
                                    </Box>
                                )}
                            </DataSection>
                        </>
                    )}
                </DialogContent>
            </FullScreenDialog>
        </StyledContainer>
    ) : loading ? (
        <LoaderContainer>
            <CircularProgress />
        </LoaderContainer>
    ) : null;
};

export default SkedAC;
