import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { loginWithToken, getMatchData, getLitigeData, fetchNickname, checkIfManager, createLitige, addSpecificRequest, checkNbrSpecifics, checkTeam, sendNotification } from '../services/api';

export const useStartLogic = () => {
    const { matchId, userToken } = useParams();
    const { t, i18n } = useTranslation();
    const [, forceUpdate] = useState();
    const [user, setUser] = useState(null);
    const [matchData, setMatchData] = useState(null);
    const [litigeData, setLitigeData] = useState(null);
    const [isHomeTeam, setIsHomeTeam] = useState(false);
    const [isManager, setIsManager] = useState(false);
    const [userTeamName, setUserTeamName] = useState(null);
    const [rosters, setRosters] = useState({ home: [], away: [] });
    const [logoLoaded, setLogoLoaded] = useState({ home: false, away: false });
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOptionLabel, setSelectedOptionLabel] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [expiredToken, setExpiredToken] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [mossAndMcReq, setMossAndMcReq] = useState(true);
    const [requestFull, setRequestFull] = useState(false);
    const [homeTeamNotFound, setHomeTeamNotFound] = useState(false);
    const [awayTeamNotFound, setAwayTeamNotFound] = useState(false);

    const arbitrationOptions = [
        { value: "verifAC", label: t("verifAC") },
        { value: "technical_problem", label: t("arbitration_technical_problem") },
        { value: "score_contestation", label: t("arbitration_score_contestation") },
        { value: "game_setting_error", label: t("arbitration_game_setting_error") },
        { value: "match_sabotage", label: t("arbitration_match_sabotage") },
        { value: "complice_triche", label: t("arbitration_cheating_accomplice") },
        { value: "complice_ban", label: t("arbitration_ban_accomplice") },
        { value: "discriminatory_behavior", label: t("arbitration_discriminatory_behavior") },
        { value: "boot_ip_flooding", label: t("arbitration_boot_ip_flooding") }
    ];

    useEffect(() => {
        const handleLanguageChange = () => {
            forceUpdate({});
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userData = await loginWithToken(userToken);
                if (userData.status === 'error') {
                    setExpiredToken(true);
                } else {
                    setUser(userData);

                    const matchDataResult = await getMatchData(matchId);

                    if(matchDataResult?.match_team_home_id == null || matchDataResult?.match_team_away_id == null) {
                        setExpiredToken(true);
                        return;
                    }

                    if(matchDataResult?.match_team_home_roster.length > 1 || matchDataResult?.match_team_away_roster.length > 1) {
                        const homeTeam = await checkTeam(matchDataResult?.match_team_home_id);
                        const awayTeam = await checkTeam(matchDataResult?.match_team_away_id);
                        
                        if(homeTeam?.status === 'error') {
                            setHomeTeamNotFound(true);
                        }

                        if(awayTeam?.status === 'error') {
                            setAwayTeamNotFound(true);
                        }
                    }

                    setMatchData(matchDataResult);
                    
                    const litigeDataResult = await getLitigeData(matchId);

                    setLitigeData(litigeDataResult);

                    const userTeams = userData?.data?.user_teams;

                    if (userTeams && matchDataResult) {
                        if (Array.isArray(userTeams)) {                        
                            const isHomeTeamValue = userTeams.includes(matchDataResult.match_team_home_id);
                            setIsHomeTeam(isHomeTeamValue);

                            if(matchDataResult?.match_team_home_roster.length > 1 && matchDataResult?.match_team_away_roster.length > 1) {
                                const teamId = isHomeTeamValue ? matchDataResult.match_team_home_id : matchDataResult.match_team_away_id;
                                const managerData = await checkIfManager(userData?.data?.user_id, teamId);
                                managerData.isManager > 0 ? setIsManager(true) : setIsManager(false);
                                setUserTeamName(managerData.teamName);
                            } else {                    
                                let isManagerDuel = {
                                    isManager: userData?.data?.user_id === matchDataResult?.match_team_home_id ? 1 : userData?.data?.user_id === matchDataResult?.match_team_away_id ? 1 : 0,
                                    teamName: userData?.data?.user_nickname
                                }
                                isManagerDuel.isManager > 0 ? setIsManager(true) : setIsManager(false);
                                setUserTeamName(isManagerDuel.teamName);
                            }

                            if (!userTeams.includes(matchDataResult.match_team_home_id) && !userTeams.includes(matchDataResult.match_team_away_id)) {
                                if(user?.data?.user_admin < 1) {
                                    window.location.href = '/';
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };

        fetchData();
    }, [matchId, userToken]);

    useEffect(() => {
        const fetchRosters = async () => {
            if (matchData) {
                const roster_home = matchData?.match_team_home_roster;
                const roster_away = matchData?.match_team_away_roster;

                const fetchRosterNicknames = async (roster) => {
                    return await Promise.all(roster.map(async (player) => {
                        const nickname = await fetchNickname(player.id);
                        return { id: player.id, nickname: nickname || "Inconnu" };
                    }));
                };

                const homeRoster = await fetchRosterNicknames(roster_home);
                const awayRoster = await fetchRosterNicknames(roster_away);

                setRosters({ home: homeRoster, away: awayRoster });
            }
        };

        fetchRosters();
    }, [matchData]);

    const handleLogoLoad = (team) => {
        setLogoLoaded(prev => ({ ...prev, [team]: true }));
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        const option = arbitrationOptions.find(opt => opt.value === value);
        setSelectedOptionLabel(option ? option.label : '');

        if (value !== 'demande_moss' && value !== 'demande_mc') {
            setSelectedPlayer(null);
        }
    };

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handlePlayerSelection = (event) => {
        const selectedId = event.target.value;
        const selectedPlayerObject = rosters[isHomeTeam ? 'away' : 'home'].find(player => player.id === selectedId);
        
        if (selectedPlayerObject) {
            setSelectedPlayer(selectedPlayerObject);
        } else {
            console.error("Joueur sélectionné non trouvé dans la liste des joueurs");
        }
    };

    const handleProceed = async () => {
        let arbitrationDetails = {
            creator_id: user?.data?.user_id,
            match_id: matchData?.match_id,
            ladder: matchData?.match_ladder_id,
            match_team_home_id: matchData?.match_team_home_id,
            [isHomeTeam ? "match_team_home_referent" : "match_team_away_referent"]: user?.data?.user_nickname,
            match_team_away_id: matchData?.match_team_away_id,
            arbitrage_subject: selectedOption,
            additional_sujet: null,
            [isHomeTeam ? "local_home" : "local_away"]: localStorage.getItem('selectedLanguage'),
            creatorNickname: user?.data?.user_nickname,
            submitted_by: user?.data?.user_nickname
        };

        if(selectedOption === 'demande_moss' || selectedOption === 'demande_mc') {
            const currentTime = new Date();
            const scoreTime = new Date(matchData?.score_submit_at);
            scoreTime.setHours(scoreTime.getHours() + 1);
            const timeDifference = (currentTime - scoreTime) / 1000 / 60;
    
            if(timeDifference <= 10) {
                let teamId = '';
                if(isHomeTeam) {
                    teamId = matchData?.match_team_home_id;
                } else {
                    teamId = matchData?.match_team_away_id;
                }

                const checkNbr = await checkNbrSpecifics(teamId);

                if(checkNbr.count >= 5) {
                    setRequestFull(true);
                    return;
                }

                const litigeCreated = await createLitige(arbitrationDetails);
                await addSpecificRequest(arbitrationDetails, selectedPlayer, false);
                setLitigeData(litigeCreated);

                const teamTag = isHomeTeam ? matchData?.match_team_home_tag : matchData?.match_team_away_tag;
                const rosterToNotify = isHomeTeam ? matchData?.match_team_away_roster : matchData?.match_team_home_roster;
    
                await Promise.all(rosterToNotify.map(player => sendNotification(player.id, matchData?.match_id, teamTag, userToken)));

                handleCloseModal();
            } else {
                setMossAndMcReq(false);
            }
        } else {
            const litigeCreated = await createLitige(arbitrationDetails);
            setLitigeData(litigeCreated);

            const teamTag = isHomeTeam ? matchData?.match_team_home_tag : matchData?.match_team_away_tag;
            const rosterToNotify = isHomeTeam ? matchData?.match_team_away_roster : matchData?.match_team_home_roster;

            await Promise.all(rosterToNotify.map(player => sendNotification(player.id, matchData?.match_id, teamTag, userToken)));

            handleCloseModal();
        }
    };

    return {
        user,
        matchData,
        litigeData,
        isHomeTeam,
        isManager,
        userTeamName,
        rosters,
        logoLoaded,
        selectedOption,
        selectedOptionLabel,
        openModal,
        expiredToken,
        selectedPlayer,
        arbitrationOptions,
        mossAndMcReq,
        requestFull,
        handleLogoLoad,
        handleOptionChange,
        handleOpenModal,
        handleCloseModal,
        handlePlayerSelection,
        handleProceed,
        homeTeamNotFound,
        awayTeamNotFound,
        t
    };
};
